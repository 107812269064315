




















import { Component, Vue, Watch } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";
@Component({})
export default class Workers extends Vue {
  members = [
    {
      index: 1,
      title: this.$t("aboutUsPage.ourTeam.andrzej.title") as TranslateResult,
      name: this.$t("aboutUsPage.ourTeam.andrzej.name") as TranslateResult,
      position: this.$t("aboutUsPage.ourTeam.andrzej.role") as TranslateResult,
      description: this.$t(
        "aboutUsPage.ourTeam.andrzej.description"
      ) as TranslateResult
    },
    {
      index: 2,
      title: this.$t("aboutUsPage.ourTeam.ola.title") as TranslateResult,
      name: this.$t("aboutUsPage.ourTeam.ola.name") as TranslateResult,
      position: this.$t("aboutUsPage.ourTeam.ola.role") as TranslateResult,
      description: this.$t(
        "aboutUsPage.ourTeam.ola.description"
      ) as TranslateResult
    },
    {
      index: 3,
      title: this.$t("aboutUsPage.ourTeam.ania.title") as TranslateResult,
      name: this.$t("aboutUsPage.ourTeam.ania.name") as TranslateResult,
      position: this.$t("aboutUsPage.ourTeam.ania.role") as TranslateResult,
      description: this.$t(
        "aboutUsPage.ourTeam.ania.description"
      ) as TranslateResult
    },
    {
      index: 4,
      title: this.$t("aboutUsPage.ourTeam.marta.title") as TranslateResult,
      name: this.$t("aboutUsPage.ourTeam.marta.name") as TranslateResult,
      position: this.$t("aboutUsPage.ourTeam.marta.role") as TranslateResult,
      description: this.$t(
        "aboutUsPage.ourTeam.marta.description"
      ) as TranslateResult
    }
  ];

  @Watch("$store.state.language")
  onPropertyChanged(value: string, oldValue: string) {
    this.members = [
      {
        index: 1,
        title: this.$t("aboutUsPage.ourTeam.andrzej.title") as TranslateResult,
        name: this.$t("aboutUsPage.ourTeam.andrzej.name") as TranslateResult,
        position: this.$t(
          "aboutUsPage.ourTeam.andrzej.role"
        ) as TranslateResult,
        description: this.$t(
          "aboutUsPage.ourTeam.andrzej.description"
        ) as TranslateResult
      },
      {
        index: 2,
        title: this.$t("aboutUsPage.ourTeam.ola.title") as TranslateResult,
        name: this.$t("aboutUsPage.ourTeam.ola.name") as TranslateResult,
        position: this.$t("aboutUsPage.ourTeam.ola.role") as TranslateResult,
        description: this.$t(
          "aboutUsPage.ourTeam.ola.description"
        ) as TranslateResult
      },
      {
        index: 3,
        title: this.$t("aboutUsPage.ourTeam.ania.title") as TranslateResult,
        name: this.$t("aboutUsPage.ourTeam.ania.name") as TranslateResult,
        position: this.$t("aboutUsPage.ourTeam.ania.role") as TranslateResult,
        description: this.$t(
          "aboutUsPage.ourTeam.ania.description"
        ) as TranslateResult
      },
      {
        index: 4,
        title: this.$t("aboutUsPage.ourTeam.marta.title") as TranslateResult,
        name: this.$t("aboutUsPage.ourTeam.marta.name") as TranslateResult,
        position: this.$t("aboutUsPage.ourTeam.marta.role") as TranslateResult,
        description: this.$t(
          "aboutUsPage.ourTeam.marta.description"
        ) as TranslateResult
      }
    ];
  }
}
