

















import { Component, Vue } from "vue-property-decorator";
import { ScienceIlustration } from "./ilustrations";
@Component({
  components: {
    ScienceIlustration
  }
})
export default class AboutUsMain extends Vue {}
