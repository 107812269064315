<template>
  <div class="about-us-page">
    <AboutUsContainer />
    <Workers />
  </div>
</template>


<script>
import Workers from "@/components/Workers.vue";
import AboutUsContainer from "@/components/AboutUsContainer.vue";

export default {
  name: "AboutUsPage",
  components: {
    Workers,
    AboutUsContainer
  }
};
</script>